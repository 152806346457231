.Dates {
    background-color: rgba(82, 9, 146, 0.35);
    animation: fadeInRight;
    animation-duration: 1s;
    width: 100vw;
    height: 60vh;
    position: fixed;
    right: 0;
    top: 25%;
    text-align: center;
    display: flex;
    justify-content: flex-end;
}

.cities {
    display: flex;
    column-gap: 12vmin;
    text-transform: uppercase;
    color: greenyellow;
    font-size: 5vmin;
    margin-right: 32vmin;
    justify-content: center;
}

.cities h3 {
    background-image: url('/public/assets/paint.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.city li {
    font-family: 'Roboto Mono', monospace;
    color: white;
    line-height: 1.5;
    font-size: 2vmin;
}

.city h6 {
    font-size: 2.5vmin;
    margin-top: 8vmin;
    margin-bottom: 0;
    color: greenyellow;
}

.dates {
    margin: -2.5vmin;
}

.salon-icons i {
    padding: 0 1vmin;
}

.address li {
    font-size: 1.8vmin;
    text-transform: none;
}

@media only screen and (max-width: 1500px) {

    .Dates {
        justify-content: center;
        height: 50vh;
        top: 25%;
    }

    .cities {
        margin: 0 auto;
        column-gap: 5vmin;
    }

    .cities h3 {
        font-size: 6vmin;
    }

    .cities li {
        font-size: 2.5vmin;
    }

    .city {
        width: 45vmin;
    }

    .city h6 {
        font-size: 3.5vmin;
    }

    .address li {
        font-size: 2.2vmin;
    }

}

@media only screen and (max-width: 500px) {

    .Dates {
        justify-content: center;
        align-items: center;
        height: 55vh;
        top: 28%;
    }

    .cities {
        margin: 0 auto;
        margin-top: -8vmin;
    }

    .cities h3 {
        font-size: 8vmin;
    }

    .cities li {
        font-size: 3.5vmin;
    }

    .city h6 {
        font-size: 3.6vmin;
        margin-top: 10vmin;
    }

    .address li {
        font-size: 2.75vmin;
    }

    .salon i {
        font-size: 5vmin;
        padding: 2vmin;
    }
    
}