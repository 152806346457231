.About {
    background-color: rgba(82, 9, 146, 0.35);
    animation: fadeInRight;
    animation-duration: 1s;
    width: 100vw;
    height: 60vh;
    position: fixed;
    right: 0;
    top: 25%;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.about-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 15vmin;
    text-transform: uppercase;
    color: greenyellow;
    font-size: 5vmin;
    padding-right: 28vmin;
    margin-top: -4vmin;
    height: 55vh;
}

.about-info h3 {
    background-image: url('/public/assets/paint.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-bottom: 1px solid;
    height: 11vmin;
    display: flex;
    align-items: center;
}

.about-info p {
    font-family: 'Roboto Mono', monospace;
    text-transform: none;
    font-size: 1.5vmin;
    color: white;
    width: 60vmin;
    margin-top: -1vmin;
}

@media only screen and (max-width: 1500px) {

    .About {
        justify-content: center;
        height: 50vh;
        top: 25%;
    }

    .about-info {
        padding: 0;
        margin: 0;
    }

    .about-info h3 {
        font-size: 6vmin;
        height: 15vmin;
    }

    .about-info p {
        font-size: 2vmin;
        width: 70vw;
    }

}

@media only screen and (max-width: 500px) {

    .About {
        justify-content: center;
        height: 55vh;
        top: 28%;
    }

    .about-info {
        padding: 0;
        margin: 0;
    }

    .about-info h3 {
        font-size: 8vmin;
        height: 15vmin;
    }

    .about-info p {
        font-size: 2.7vmin;
        width: 84vw;
    }

}