.Nav {
    background-color: navy;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    animation: fadeInDown 2s;
}

h1 {
    position: fixed;
    left: 0;
    text-transform: uppercase;
    font-size: 6vmin;
    color: greenyellow;
    padding: 1vmin 0 0 2vmin;
}

ul {
    display: flex;
    column-gap: 6vmin;
    background-color: whitesmoke;
    background-image: url('/public/assets/nav-bg.jpg');
    background-size: cover;
    height: 10vh;
    width: 100vmin;
    align-items: center;
    justify-content: right;
    position: fixed;
    right: 0;
    padding: 5vmin;
}

li {
    color: navy;
    list-style-type: none;
    text-transform: uppercase;
    font-size: 3vmin;
}

.Nav a {
    color: navy;
}

i {
    font-size: 3.5vmin;
}

.material-symbols-outlined {
    font-size: 4vmin;
    line-height: normal;
}

.title-hover, .hover {
    display: inline-block;
}

.title-hover {
    color: greenyellow;
}

.hover {
    position: relative;
}
  
.title-hover::after, .hover::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: navy;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.title-hover::after {
    background-color: greenyellow;
}
  
.title-hover:hover::after, .hover:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

@media only screen and (max-width: 1500px) {
    
    .Nav {
        animation: none;
    }

    .Nav h1 {
        font-size: 7.5vmin;
        text-align: center;
        width: 100vw;
        padding: 0;
        margin-top: 5vmin;
        animation: fadeInDown 1s;
    }
  
    .Nav ul {
        width: 100vw;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 830%;
        animation: fadeInUp 2s;
        column-gap: 4vmin;
    }

    .Nav li {
        font-size: 3.5vmin;
    }

    i {
        font-size: 4vmin;
    }

    .material-symbols-outlined {
        line-height: 1;
        font-size: 4.5vmin;
        padding-top: .5vmin;
    }

}

@media only screen and (max-width: 500px) {

    .Nav {
        animation: fadeInDown 2s;
    }
    
    .Nav h1 {
        width: 100%;
        text-align: center;
        font-size: 6.5vmin;
        padding: 0;
    }

    .Nav {
        height: 9vh;
    }
    
    .Nav ul {
        position: absolute;
        width: 100vw;
        top: 70%;
        justify-content: center;
        height: 4vh;
        column-gap: 3.75vmin;
    }

    .material-symbols-outlined {
        font-size: 5vmin;
    }

    i {
        padding-bottom: 1vmin;
    }

}